<template>
  <div>
      <h1>INVEST IN EMITUS</h1>

      <span class="subtitle">Pre-seed round | €250,000 | Stage: Private beta</span>

      <!-- <span class="quote" > "The easies and most efficient way to sell online sessions"</span> -->

      <h2>1. PROBLEM</h2>

      <p>
        Many professionals are migrating to a remote type of work. However, this transition is usually expensive and time-consuming. In particular, selling online sessions comes with though challenges like: 
      </p>

      <br>

      <ul>
        <li>
        Finding and generating trust for new clients

        </li>
        <li>
        Booking logistics

        </li>
        <li>
         Receiving international payments

        </li>
        <li>
         Creating an online presence with complete information

        </li>
        <li>
          Coordinating video calls, bookings and payments systems

        </li>
      </ul>


      <br>

      <p>
        Most of them <b>  spend months and thousands of euros building their website </b> . Then, they need to use different services to coordinate bookings, payments and video calls. The process is messy and requires a lot of effort.
      </p>

      <h2>2. SOLUTION</h2>

      <div class="video-container">
        <iframe
          src="https://player.vimeo.com/video/641060307?h=0659757736"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          :style="`max-width:${maxWidth}px; max-height: ${maxHeight}px;`"
          ></iframe>
      </div>

      <p>
        <b> Emitus is the easiest and most efficient way to sell online sessions. </b> Anyone can start selling online sessions in 5 minutes without initial investment.
      </p>
      <br>

      <p>
        Users create a profile, set availability and share it on social networks. Their clients start booking sessions, and they receive the money in their bank account. Emitus takes care of <b> bookings, payments and videocall in one place </b>.
      </p>

      <h2>3. MARKET</h2>

      <p>
        Emitus is for anyone with a particular skill or knowledge that is valuable for someone else. It's especially useful for freelancers, consultants, online teachers and influencers in different industries: Nutritionists, lawyers, language teachers, videogame players, accountants, among others.
      </p>
      <br>
      <p>
        <a href="* https://www.slideshare.net/mobile/upwork/freelancing-in-america-2017 " target="_blank">
        > 51% of the workforce will be freelancers by 2027
        </a>
      </p>
      <p>
        <a href="https://www.slideshare.net/mobile/upwork/freelancing-in-america-2017 " target="_blank">
        > 370 billion USD is the market size of eLearning by 2026
        </a>
      </p>
      <p>
        <a href="https://info.mention.com/instagram-engagement-report-2020" target="_blank">
        > +400 million Instagram users are considered micro-influencers
        </a>
      </p>
      <br>

      <h2>4. CURRENT ALTERNATIVES</h2>

      <ul>
        <li>
          Codementor, Growthmentor or ClarityFm are reasonably good solutions for their respective segments. However, they take a commission up to 40% of their income, and it's pretty difficult for anyone to start.
        </li>

        <li>
          Booking platforms like Calendly, Simplybook or Setmore mainly focus on B2B off-line bookings. They have integrations for online bookings, but it's complicated and requires additional fees. 
        </li>

        <li>
          Marketplaces like Upwork or Fiverr are meant for project completion. You can hire a professional by the hour, but not for online sessions. 
        </li>

        <li>
          Many professionals use CMS like WordPress or Webflow to build their websites. It usually takes much time (several months), some money (+$3,000) and results are sub-optimal in most cases.
        </li>
      </ul>

      <h2>5. MONETIZATION</h2>

      <p>
        We charge 1€ per successful booking. 90€ MRR/user if 3 booking/day.
      </p>

      <h2>6. MANAGEMENT TEAM</h2>

      <h3>Carlos Cardona - CEO</h3>

      <ul>
        <li>
          Founder of 4 startups
        </li>
        <li>
          Full-stack developer
        </li>
        <li>
          UX/UI designer
        </li>
        <li>
          MScBA Entrepreneurship
        </li>
        <li>
          YCombinator Startupsschool Alumni
        </li>
      </ul>

      <br>

      <h3>Leidy Gomez - UX/UI Designer</h3>

      <ul>
        <li>
          Academic national award
        </li>
        <li>
          Graphic Designer
        </li>
      </ul>

      <br>

      <h3>Yoser Erazo - Fullstack Developer</h3>

      <ul>
        <li>
          Startup co-founder x2
        </li>
        <li>
          BS Computer Science
        </li>
      </ul>

      <br>

      <h3>Valentina Ramirez - Advisor</h3>

      <ul>
        <li>
          Startup co-founder x2
        </li>
        <li>
          Strategic Digital Planning - Geometry Global
        </li>
        <li>
          MSc. Human-Technology Interaction
        </li>
        <li>
          Younoodle - Stanford University
        </li>
      </ul>

      <br>

      <h3>Miro Kapka- Advisor</h3>

      <ul>
        <li>
          Manager, TPM team  @ Atlassian
        </li>
        <li>
          MScBA Entrepreneurship
        </li>
      </ul>

      <br>

      <h3>Leonardo Quiñones - Advisor</h3>

      <ul>
        <li>
          DevOps @ Booking
        </li>
        <li>
          MSc Computer Science
        </li>
      </ul>

      <br>

      <h3>Jorge García - Advisor</h3>

      <ul>
        <li>
          Design Director @ La Haus
        </li>
        <li>
          Design Leader @ McKinsey & Co.
        </li>
      </ul>

      <br>


      <div class="bottom-bar">
        <button id="c2a" class="button-primary-medium">
          <a style="display: flex; gap: 10px; color: #fff; margin-left: 33px; font-size: 1.3em;" target="_blank" href="https://api.whatsapp.com/send?phone=+34643943764&text=Hi, I'm interesed in investing in emitus."> I'm interested</a>
        </button>
      </div>

  </div>
</template>

<script>
export default {
  name: 'Invest',
  components: {},
  data () {
    return {
    }
  },
  computed: {
    // Max Width
    maxWidth: function () {
      const maxWidth = window.innerWidth
      return maxWidth
    },

    maxHeight: function () {
      const maxHeight = this.maxWidth * (360 / 640)
      return maxHeight
    },

  },
  metaInfo: {
    title: 'emitus | Invest'
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  margin-left: 7%;
}

#c2a {
position: fixed;
    right: 30px;
    width: 200px;
    height: 55px;
    bottom: 10px;
}

.bottom-bar {
  position: fixed;
  right: 15px;
  bottom: 0px;
  width: 100%;
  height:70px;
}

.quote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Nunito";
  font-size: 28px;
  line-height: 28px;
  color: #272727;
  margin-left: 4%;
  margin-right: 7%;
  font-style: italic;
  margin-top: 60px;
}

.video {
  max-width: 100%;
}

div {
  padding-top: 20px;
  padding-bottom: 20px;
}

h1 {
  font-family: Basier circle;
  font-weight: bold;
  font-size: 30px;
  color: #111747;
  margin-left: 4%;
  padding-top: 10%;
}

h2 {
font-family: Basier circle;
    font-size: 19px;
    margin-top: 35px;
    margin-left: 5%;
    font-weight: bolder;
    color: #111747;
    margin-bottom: 12px;
}

h3 {
  margin-left: 5%;
  font-family: Basier circle;
  font-size: 15px;
  color: #111747;
}

p {
  font-family: "Nunito";
  font-size: 1.2em;
  line-height: 33px;
  color: #444444;
  margin-left: 7%;
  margin-right: 7%;
  max-width: 640px;
}

li {
  font-family: "Nunito";
  font-size: 1.2em;
  line-height: 33px;
  color: #444444;
  margin-left: 9%;
  margin-right: 7%;
  max-width: 640px;
  list-style-type: square;
}

.subtitle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Nunito";
  font-size: 1.4em;
  line-height: 28px;
  color: #444444;
  margin-left: 4%;
  margin-right: 7%;
}

@media (max-width: 500px) {


.video-container {
    margin-left: 0%;
}

}
</style>
